
























































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import countries from '@/assets/countries.json';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Product } from '@/store/modules/application/types/application.types';
import { get as _get } from 'lodash';
import { RootState } from '../../../store/store';

const initialFormValue: BillingAddressFormValues = {
  streetAddress: '',
  city: '',
  country: null
};

interface BillingAddressFormValues {
  streetAddress: string;
  city: string;
  country: string | null;
}

interface CountryOption {
  name: string;
  code: string;
}

@Component({
  components: {
    ValidationProvider,
    ValidationObserver
  }
})
export default class BillingAddressForm extends Vue {
  @Prop()
  public product!: Product;

  public form: BillingAddressFormValues = {
    ...initialFormValue
  };

  get countries(): CountryOption[] {
    return countries || [];
  }

  get price(): number | undefined {
    if (this.product) {
      const { netPrice, gstAmount } = this.product.chargeDetail;

      if (this.form.country === 'Australia') {
        return Math.round(netPrice + gstAmount);
      }

      return Math.round(netPrice);
    }
  }

  get gstPercent() {
    if (this.product) {
      const { netPrice, gstAmount } = this.product.chargeDetail;
      const totalAmount: number = +(Math.round(netPrice + gstAmount) / 100);
      const gstPrice: number = +(gstAmount / 100).toFixed(2);

      return `(${Math.round(
        (totalAmount / (totalAmount - gstPrice) - 1) * 100
      )}%)`;
    }
  }

  get gstApplicable() {
    const country = this.form.country;
    if (country === 'Australia') {
      return true;
    }
    return false;
  }

  public cancelPurchase() {
    return this.$router.push('/');
  }

  public handleSubmit() {
    // @ts-ignore
    this.$refs.observer.validate().then((validated) => {
      if (validated === false) {
        return;
      }
      return this.$emit('handlePayment', this.form);
    });
  }

  get createOrderLoading() {
    return (this.$store.state as RootState).order.apiState.createOrder.loading;
  }
  get createOrderSuccess() {
    return (this.$store.state as RootState).order.apiState.createOrder.success;
  }
}
