














import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';

import { RootState } from '@/store/store';
import countries from '@/assets/countries.json';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Container from '@/components/Container.vue';
import Card from '@/components/Card.vue';
import BillingAddressForm from './components/BillingAddressForm.vue';
import {
  Product,
  ApplicationState
} from '@/store/modules/application/types/application.types';
import {
  CreateOrderPayload,
  BillingAddress
} from '@/store/modules/order/order.types';
import { AxiosResponse } from 'axios';
import { get as _get } from 'lodash';
import { DialogProgrammatic as Dialog } from 'buefy';
import { ApiState } from '@/store/types/general.types';

@Component({
  components: {
    ValidationObserver,
    Container,
    Card,
    BillingAddressForm
  }
})
export default class ProductCheckoutPage extends Vue {
  @State(({ order }: RootState) => order.apiState.createOrder)
  public createOrderState!: ApiState;

  @Action('application/getProduct')
  public getProduct!: ({ productId }: { productId: number }) => Promise<void>;

  @Action('order/createOrder')
  public createOrder!: (payload: CreateOrderPayload) => Promise<AxiosResponse>;

  @State(({ application }: RootState) => application.product)
  public product!: Product;

  get productId() {
    return +this.$route.params.productId;
  }

  public async handlePayment(billingAddress: BillingAddress) {
    const response = await this.createOrder({
      productId: this.product.id,
      billingAddress
    });

    this.redirectPaymentGateway(response);
  }

  public redirectPaymentGateway(response: any) {
    if (_get(response, 'data.paymentRequired')) {
      const responseCode = _get(response, 'data.APIResponse.ResponseCode');
      const responseText =
        'Unable to connect to payment gateway due to invalid login credential. Please contact an administrator.';

      if (responseCode === 0) {
        const authKey = _get(response, 'data.AuthKey');
        return (window.location.href = `${process.env.VUE_APP_BPOINT_REDIRECT_PAYMENT_URL}?in_pay_token=${authKey}`);
      }

      return this.$buefy.dialog.confirm({
        title: `Error code ${responseCode}`,
        message: `<p class="subtitle"> ${responseText}<p>`,
        confirmText: 'Try again',
        canCancel: ['button'],
        onConfirm: () => {
          this.redirectPaymentGateway(response);
        },
        cancelText: 'Cancel',
        onCancel: () => {
          this.cancelPurchase();
        }
      });
    }
  }

  public cancelPurchase() {
    return this.$router.push('/');
  }

  public async mounted() {
    await this.getProduct({ productId: this.productId });
  }

  @Watch('createOrderState.error')
  public createOrderSuccess(error: Error) {
    if (error) {
      const responseText =
        'Unable to connect to payment gateway. Please try again later.';
      return this.$buefy.dialog.confirm({
        title: `Error`,
        message: `<p class="subtitle"> ${responseText}<p>`,
        confirmText: 'OK'
      });
    }
  }
}
